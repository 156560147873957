// App reducer

import { 
  SET_EVENT_OWNER_ID,
  SET_USERID,
  SET_TEAM_NAME,
  SET_ERROR,
  CLEAR_ERROR,
  SET_LOADING,
  CLEAR_LOADING } from './app.types'


const INITIAL_STATE = {
  eventOwnerId: "0elwnjhyAMOtbWhTrtfxoyJnzsJ2",
  userId: "",
  teamName: "",
  errorMessage: '',
  errorCallback: ()=>{},
  loading: false,
  isAuthenticated: false
};

const appReducer = (state = INITIAL_STATE, action) => {

    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {

      case SET_EVENT_OWNER_ID:
          return { 
            ...updatedState, 
            eventOwnerId: action.payload.id
          };

      case SET_USERID:
          return { 
            ...updatedState, 
            userId: action.payload.userId
          };

      case SET_ERROR:
          return { 
            ...updatedState, 
            errorMessage: action.payload.errorMessage,
            errorCallback: action.payload.errorCallback 
          };

      case CLEAR_ERROR:
          return { 
            ...updatedState, 
            errorMessage: "",
            errorCallback: ()=>{} 
          };

      case SET_LOADING:
          return { 
            ...updatedState, 
            loading: true
          };

      case CLEAR_LOADING:
          return { 
            ...updatedState, 
            loading: false
          };

      case SET_TEAM_NAME:
          return { 
            ...updatedState, 
            teamName: action.payload.teamName
          };


      default: return state;
      
    }

};

export default appReducer;