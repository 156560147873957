import React from 'react'
import { useEffect } from 'react'
import Button from "@mui/material/Button";
import signup from "./signoutpage.scss"
import { getAuth, signOut } from 'firebase/auth';
import store from '../redux/store'
import { setUserId } from '../redux/App/app.actions'
import { useNavigate, useLocation } from 'react-router-dom'

export default function SignOutPage() {

  const auth = getAuth()

  const navigate = useNavigate()

  const { state } = useLocation(); // state is any or unknown

  const destination = state?.destination ?? '/';


  useEffect(()=>{

  localStorage.removeItem("teamName");
  store.dispatch( setUserId("") );

  signOut(auth)
    .then(() => {
      navigate('/signin', {state: {destination}});
    })
    .catch((error) => {
      console.log(error);
    });


  },[])


  return (
    <div className="signout">
      YOU ARE SIGNED OUT
    </div>
  );
}