import { combineReducers } from 'redux';
import appReducer from './App/app.reducer';
import gameReducer from './Game/game.reducer';
import eventReducer from './Event/event.reducer';


const rootReducer = combineReducers({

    app: appReducer,
    game: gameReducer,
    event: eventReducer,

});

export default rootReducer;
