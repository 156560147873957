import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Navigate, Outlet, useParams, useLocation } from 'react-router-dom';

const GameRoute = ({ children, isAllowed, isTeamNameSet }) => {

  const location  = useLocation()

  if (!isAllowed) {
    return <Navigate to={'/signin'} state={{destination:location.pathname}} replace />;
  } else {
    if (!isTeamNameSet) {
      return <Navigate to={'/setteamname'} state={{destination:location.pathname}} replace />;
    }
  }

  return children ? children : <Outlet />;
}

export default GameRoute
