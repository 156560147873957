// App actions

import { 
	SET_EVENT_OWNER_ID,
	SET_USERID,
	SET_TEAM_NAME,
	SET_ERROR,
	CLEAR_ERROR,
	SET_LOADING,
	CLEAR_LOADING,

} from './app.types'

export const setEventOwnerId = (eventOwnerId) => {

	return {
		type: SET_EVENT_OWNER_ID,
		payload: {
			id: eventOwnerId
		}
	};

}



export const setUserId = (userId) => {

	return {
		type: SET_USERID,
		payload: {
			userId: userId
		}
	};

}

export const setTeamName = (teamName) => {

	return {
		type: SET_TEAM_NAME,
		payload: {
			teamName: teamName
		}
	};

}


export const setError = (errorMessage,errorCallback) => {

	return {
		type: SET_ERROR,
		payload: {
			errorMessage: errorMessage,
			errorCallback: errorCallback
		}
	};

}



export const clearError = () => {

	return {
		type: CLEAR_ERROR
	};

}



export const setLoading = () => {

	return {
		type: SET_LOADING,
	};

}



export const clearLoading = () => {

	return {
		type: CLEAR_LOADING
	};

}