import React, { useEffect, useState } from 'react'
import './teamscoreview.scss';
import { ANSWERKEYS } from '../resources/answerkeys-constants'
import { endQuestion } from '../redux/Game/game.actions'
import store from '../redux/store'


const TeamScoreView = ({score, elaboration}) => {

    //const elaboration = "TEST"
    //const score = "180"

    return (
        <div className="team-score-view">
            <div className="elaboration">YOUR TEAM SCORE</div>
            <div className="yourscore">{score}</div>
            {
                (elaboration !== "" && elaboration) ? <p>{elaboration}</p> : null
            }
        </div>
    )

}

export default TeamScoreView
