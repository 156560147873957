import { initializeApp } from "firebase/app";
import { getDatabase, orderByValue, query, ref, orderByChild, onValue, get, child, set, push, serverTimestamp, remove} from "firebase/database";
import { startGame } from '../redux/Event/event.actions'
import { setGetReady, startQuestion, setMessage } from '../redux/Game/game.actions'
import { setActiveGame, setAvailableQuestions, setQuestionAsUnavailable } from '../redux/Event/event.actions'

import { updatePlayersList, updateScore } from '../redux/Game/game.actions'
import { setTeamName } from '../redux/App/app.actions'
import store from '../redux/store'



const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

let serverOffset = 0;



export const setupEventListener = (eventOwnerId, eventId) => {

  const eventListenerRef = ref(db, `/events/${eventOwnerId}/${eventId}/activegame`);

  onValue(eventListenerRef, (snapshot) => {
    store.dispatch( setActiveGame( snapshot.val() ) );
  });

}



export const setupGetReadyListener = (eventOwnerId, gameId) => {

  const gameListenerRef = ref(db, `/games/${eventOwnerId}/${gameId}/getready`);

  onValue(gameListenerRef, (snapshot) => {

    const getready = snapshot.val();

    store.dispatch(setGetReady(getready))

  });

}


export const setupMessageListener = (eventOwnerId, gameId) => {

  const messageListenerRef = ref(db, `/games/${eventOwnerId}/${gameId}/message`);

  onValue(messageListenerRef, (snapshot) => {

    const message = snapshot.val();

    if (typeof message === 'object') {
      store.dispatch(setMessage(message))
    }

    /*
    try {
        deserializedMessageObj = JSON.parse( props.message )
        //if (deserializedMessageObj) {
            setView("MESSAGE")
        //}
    } catch (e) {
        setView("AWAITING_QUESTION")
        //return console.error(e); 
    }
    */


  });

}




export const setupGameListener = (eventOwnerId, gameId) => {

  const gameListenerRef = ref(db, `/games/${eventOwnerId}/${gameId}`);


  onValue(gameListenerRef, (snapshot) => {

    // Calculate the current server time offset to be used from here on in
    const offsetRef = ref(db, ".info/serverTimeOffset");

    onValue(offsetRef, (snap) => {
      serverOffset = snap.val();
    });

    const lastActiveQuestion = store.getState().game.activeQuestion
    const lastStartAt = store.getState().game.startAt
    const activeQuestionId = snapshot.val().activequestion;
    const adjustedStartAt = snapshot.val().startat - serverOffset;

    // Check to make sure question is set (not "none") and is diferenrt than state (it's new)

    //if (activeQuestionId !== 'none' && activeQuestionId !== store.getState().game.activeQuestion) {

    // Check if activeQuestion has changed, if so assume new question
    //if ((activeQuestionId !== lastActiveQuestion) && (adjustedStartAt !== lastStartAt) ) {
    if (adjustedStartAt !== lastStartAt) {

      const questionOrder = snapshot.val().order;
      const questionData = ref(db, `/questions/${eventOwnerId}/${activeQuestionId}`);
      store.dispatch(updatePlayersList( formatPlayersDataForState(snapshot.val().players)  ))

      get(questionData).then((ss2) => {
        if (ss2.exists()) {

          const question = ss2.val().question;
          const answer = ss2.val().answer;
          const w1 = ss2.val().w1;
          const w2 = ss2.val().w2;
          const w3 = ss2.val().w3;
          //activeQuestionId !=='none' ? store.dispatch(startQuestion( activeQuestionId, question, answer, w1, w2, w3, questionOrder, adjustedStartAt )) :null;
          store.dispatch(startQuestion( activeQuestionId, question, answer, w1, w2, w3, questionOrder, adjustedStartAt ))

        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });      

    } else {

      store.dispatch(updatePlayersList( formatPlayersDataForState(snapshot.val().players)  ))

    }

  });

}


export const getQuestionOptionsList = (eventOwnerId) => {

  let questionOptionsArr = [];

  const questionOptionsRef = query(ref(db, `/questions/${eventOwnerId}`));

  get(questionOptionsRef).then((snapshot) => {
    questionOptionsArr = snapshotToArray(snapshot)
    
    //add indexes and available as param
    questionOptionsArr = questionOptionsArr.map((obj,i)=>{
      obj.index = i
      obj.available = true
      return obj })

    store.dispatch(setAvailableQuestions(questionOptionsArr))

  }).catch((error) => {
    console.error("SOMETHING WRONG", error);
  });

  return questionOptionsArr

}





export const placeScore = (score, userId, eventOwnerId, gameId, questionId) => {

  const currentScore = findMyScoreInPlayerList(store.getState().game.playersList, userId)

  let newScore = currentScore + score
  let teamName = store.getState().app.teamName

  set(ref(db, `/games/${eventOwnerId}/${gameId}/players/${userId}/score`), newScore)
  set(ref(db, `/games/${eventOwnerId}/${gameId}/players/${userId}/teamname`), teamName)

  store.dispatch(updateScore( newScore ))

}


export const changeMessage = (on=true, eventOwnerId, gameId, type="", title="", body="") => {

  // If on is false the node will be deleted
  const messageObj = {
    type: type,
    title: title,
    body: body
  }

  if (on) {
    set(ref(db, `/games/${eventOwnerId}/${gameId}/message`), messageObj)
  } else {
    remove(ref(db, `/games/${eventOwnerId}/${gameId}/message`))
  }

}


export const setTeamNameForGame = (teamName, user, eventOwnerId, gameId) => {

  let _userTeamNameObj = {
    "teamname": teamName,
    "score" : 0
  }

  store.dispatch( setTeamName( teamName ) );

  const userNode = query(ref(db, `/games/${eventOwnerId}/${gameId}/players/${user}`));
  const playersNode = query(ref(db, `/games/${eventOwnerId}/${gameId}/players`));

  get(playersNode).then((snapshot) => {

    if (!snapshot.exists()) {

      // "players" node does not exist
      set(ref(db, `/games/${eventOwnerId}/${gameId}/players/${user}`), _userTeamNameObj)

    } else {

      //"players" node does exist
      get(userNode).then((snapshot2) => {
        if (snapshot2.exists()) {
          get(query(ref(db, `/games/${eventOwnerId}/${gameId}/players/${user}/score`))).then((userScore) => {
            store.dispatch(updateScore( userScore.val() ))
          });
          //const userScore = query(ref(db, `/games/${eventOwnerId}/${gameId}/players/${user}/score`));
          // user exists
          set(ref(db, `/games/${eventOwnerId}/${gameId}/players/${user}/teamname`), teamName)
        } else {
          set(ref(db, `/games/${eventOwnerId}/${gameId}/players/${user}`), _userTeamNameObj)
        }
      })

    }
  }).catch((error) => {
      console.error(error);
  });

}



export const setActiveQuestion = (eventOwnerId, gameId, qid) => {

  store.dispatch( setQuestionAsUnavailable( qid ) );

  set(ref(db, `/games/${eventOwnerId}/${gameId}/activequestion/`), qid)
  set(ref(db, `/games/${eventOwnerId}/${gameId}/startat/`), serverTimestamp())

}



export const triggerGetReady = (eventOwnerId, gameId, getready) => {

  set(ref(db, `/games/${eventOwnerId}/${gameId}/getready/`), getready)

}


export const resetGame = (eventOwnerId, gameId) => {

  set(ref(db, `/games/${eventOwnerId}/${gameId}/`), {"activequestion": "", "order":"0123", "startat": 0})

}





function snapshotToArray(snapshot) {
      var returnArr = [];

      snapshot.forEach(function(childSnapshot) {
          var item = childSnapshot.val();
          item.key = childSnapshot.key;

          returnArr.push(item);
      });

      return returnArr;
  }


function formatPlayersDataForState(list) {

  let arrayOfObjects = []

  if (list) {

    arrayOfObjects = Object.keys(list).map(key => {
       let ar = list[key]
       ar.playerId = key
       return ar
    })

  }


  return arrayOfObjects

}


function findMyScoreInPlayerList(list, userId) {

  const myScoreObj = list.find(obj => {
    return obj.playerId === userId
  })

  const currentScore = myScoreObj?.score || 0

  return currentScore

}



