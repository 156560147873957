import React from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { setupGameListener, setupMyScoreListener, placeScore, setTeamNameForGame } from '../utility/firebase'
import Question from '../components/Question'
import gamepage from './gamepage.scss'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../images/quizic1.png'; 

const GAME_VIEWS = {
    LEADERBOARD:        'LEADERBOARD',
    QUESTION:           'QUESTION',
    RESULT:             'RESULT',
    AWAITING_QUESTION:  'AWAITING_QUESTION'
}

const mapStateToProps = (state) => ({
  app:              state.app,
  userId:           state.app.userId,
  activeQuestion:   state.game.activeQuestion,
  question:         state.game.question,
  answer:           state.game.answer,
  w1:               state.game.w1,
  w2:               state.game.w2,
  w3:               state.game.w3,
  order:            state.game.order,
  startAt:          state.game.startAt,
  teamName:         state.app.teamName,
  playersList:      state.game.playersList,
})




const GamePage = (props) => {

    const [view, setView] = useState(GAME_VIEWS.AWAITING_QUESTION);
    const [activeQuestion, setActivequestion] = useState("");

    const navigate = useNavigate()

    const location = useLocation();

    const { eventOwnerId, gameId } = useParams()


    function handleScore(score) {

        placeScore(score, props.userId, eventOwnerId, gameId, props.activeQuestion)

    }

    useEffect(()=>{

        if (props.userId && props.userId !== "") {
            setTeamNameForGame(props.teamName, props.userId, eventOwnerId, gameId);
            const fbGameListener = setupGameListener(eventOwnerId, gameId);
        } else {
            navigate('/')
        }

    },[]);

    useEffect(()=>{

        if (props.activeQuestion) {
            setActivequestion(props.activeQuestion)
            setView("QUESTION")
        }

    },[props.activeQuestion]);


    switch (view) {

        case "AWAITING_QUESTION":
            return (
                <div className='awaiting-question'>
                    <img src={logo} width="30%" style={{opacity:"20%"}}/><br />
                    <p>Wait for the game to begin...</p>
                    <CircularProgress color="inherit" />
                </div>
            );

      case "QUESTION":
            return (
                <div className='gamepage'>
                    <Question 
                        qid={activeQuestion} 
                        question={props.question} 
                        answer={props.answer}
                        w1={props.w1}
                        w2={props.w2}
                        w3={props.w3}
                        order={props.order}
                        startAt={props.startAt}
                        handleScore={handleScore}
                        playersList={props.playersList}
                    />
                    <p style={{ display: 'none' }}>{JSON.stringify(location)}</p>
                </div>
            );

      case "RESULT":
            return (
                <div className='gamepage'>
                    <p>{JSON.stringify(location)}</p>
                    <p>Game Page - RESULT</p>
                </div>
            );

      default: return (<div>PROBLEM</div>);
      
    }

}

export default connect(mapStateToProps)(GamePage)