import React from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { setupGetReadyListener, setupGameListener, setTeamNameForGame, setupMessageListener } from '../utility/firebase'
import QuestionDisplay from '../components/QuestionDisplay'
import MessageDisplay from '../components/MessageDisplay'
import './gamepagedisplay.scss'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../images/quizic1.png'; 
import qr from '../images/qr.jpg'; 
import {QRCodeSVG} from 'qrcode.react';


const GAME_VIEWS = {
    LEADERBOARD:        'LEADERBOARD',
    QUESTION:           'QUESTION',
    RESULT:             'RESULT',
    AWAITING_QUESTION:  'AWAITING_QUESTION',
    GET_READY:          'GET_READY',
    WINNER:             'WINNER'
}

const mapStateToProps = (state) => ({
  app:              state.app,
  userId:           state.app.userId,
  activeQuestion:   state.game.activeQuestion,
  question:         state.game.question,
  answer:           state.game.answer,
  w1:               state.game.w1,
  w2:               state.game.w2,
  w3:               state.game.w3,
  order:            state.game.order,
  startAt:          state.game.startAt,
  playersList:      state.game.playersList,
  getready:         state.game.getready,
  message:          state.game.message,
})


const GamePageDisplay = (props) => {

    const [view, setView] = useState(GAME_VIEWS.AWAITING_QUESTION);
    const [activeQuestion, setActivequestion] = useState("");

    const navigate = useNavigate()
    const location = useLocation();
    const { eventOwnerId, gameId } = useParams()


    useEffect(()=>{

        if (props.activeQuestion) {
            setActivequestion(props.activeQuestion)
            setView("QUESTION")
        }

    },[props.activeQuestion]);


    useEffect(()=>{

        if (props.getready) {
            setView("GET_READY")
        }

    },[props.getready]);

    useEffect(()=>{

        props.message !== null ? setView("MESSAGE") : setView("AWAITING_QUESTION")

/*
        try {
            deserializedMessageObj = JSON.parse( props.message )
            //if (deserializedMessageObj) {
                setView("MESSAGE")
            //}
        } catch (e) {
            setView("AWAITING_QUESTION")
            //return console.error(e); 
        }
*/

    },[props.message]);


    useEffect(()=>{

        const fbGameListener = setupGameListener(eventOwnerId, gameId);
        const fbGetReadyListener = setupGetReadyListener(eventOwnerId, gameId);
        const fbMessageListener = setupMessageListener(eventOwnerId, gameId);

    },[]);

    switch (view) {

        case "GET_READY":
            return (
                <div className='gamepagedisplay'>
                    <div className='getready'>
                        GET YOUR PHONE READY!!!
                    </div>
                </div>
            );

        case "AWAITING_QUESTION":
            return (
                <div className='gamepagedisplay'>
                    <div className='awaiting-question'>
                        <h1>Scan the QR code to join the game!</h1>
                        <div className="qr-container">
                        <QRCodeSVG value={`https://www.soundcheck-live.com/#/game/${eventOwnerId}/${gameId}`} size="600" />
                        </div>
                    </div>
                </div>
            );

        case "QUESTION":
            return (
                <QuestionDisplay 
                    qid={activeQuestion} 
                    question={props.question} 
                    answer={props.answer}
                    w1={props.w1}
                    w2={props.w2}
                    w3={props.w3}
                    order={props.order}
                    startAt={props.startAt}
                    playersList={props.playersList}
                />
            );

        case "MESSAGE":
            return (
                <div className={props.message?.type === "WINNER" ? 'winners' : ''}>
                    <MessageDisplay 
                        messageObj={props.message} 
                        playersList={props.playersList}
                    />
                </div>
            );

      default: return (<div>PROBLEM</div>);
      
    }

}

export default connect(mapStateToProps)(GamePageDisplay)