import React from 'react'
import { useState } from 'react'
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import signin from "./signinpage.scss"
import { setUserId, setError } from '../redux/App/app.actions'
import store from '../redux/store'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, setPersistence, browserLocalPersistence } from 'firebase/auth';
import variables from '../styles/main.sass'


export default function SignInPage() {

  const navigate = useNavigate()

  const { state } = useLocation(); // state is any or unknown

  const destination = state?.destination ?? '/';

  const [displayLogin, setDisplayLogin] = useState(false)

  const auth = getAuth()

  onAuthStateChanged(auth, async (user)=>{
      if(user) {
        store.dispatch( setUserId(user.uid) );
        // state.destination is where we need to go eventually,
        // but if team name is not set we should go to the 
        // setteamname route including the state destination

        //  ot sure if "navigate" accepts same components as Navigate
        // <Navigate to={redirectPath} state={{destination:location.pathname}} replace />

        // Want to navigate to "state.destination" if teamname is set
        // or setteamname with destination in state for it to pass back
        //const goNow = teamName === "" ? '/setteamname' : state.destination;
        navigate(destination)
      } else {
        setDisplayLogin(true)
      }
  })

  const handleLink = (link) => {
    navigate(link,{ state: {destination: destination}}) 
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    setPersistence(auth, browserLocalPersistence)
    .then(()=>{
      signInWithEmailAndPassword(auth, data.get("email"), data.get("password"))
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        store.dispatch( setUserId(user.uid) );
        //navigate('/setteamname', {state:{destination:state.destination}})
        //navigate(state.destination)
        // ...
      })
      .catch((error) => {
        store.dispatch(setError("Something went wrong. Please try again."))
        const errorCode = error.code;
        const errorMessage = error.message;
      })
    })
    .catch((error) => {
      store.dispatch(setError("Something went wrong. Please try again."))
      const errorCode = error.code;
      const errorMessage = error.message;
    })

    console.log({
      email: data.get("email"),
      password: data.get("password"),
    })
  }





  /* THESE SHOULD BE MOVED UP TO ABSTRACT */
  const rootStyles = {
    border: `2px solid ${variables.quizic_correct}`,
    borderRadius: 2,
    '& label.Mui-focused': {
      color: variables.quizic_correct,
    },
    marginBottom: '20px'
  };

  const inputLabelStyles = {
    color: variables.quizic_correct,
    textTransform: 'capitalize',
    backgroundColor: variables.quizic_background,
    fontWeight: '700',
    paddingLeft: '6px', 
    paddingRight: '6px', 
  };

  const rootInputStyles = {
    '&:hover fieldset': {
    },
    '&:focus-within fieldset, &:focus-visible fieldset': {
      border: 'none',
    },
  };

  const inputStyles = {
    color: 'white',
    paddingLeft: '15px',
    fontSize: '20px',
  };

  const helperTextStyles = {
    color: 'blue',
  };






  return (
    <div className='signin'>
    {displayLogin ?
    <Container component="main" maxWidth="xs" >
      <Box
        sx={{  
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4">
          Sign In
        </Typography>
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          noValidate 
          sx={{ 
            mt: 1, 
            padding: '10px', 
          }}
        >
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            sx={{ ...rootStyles }}
            InputLabelProps={{
              sx: {
                ...inputLabelStyles
              },
            }}
            InputProps={{
              sx: {
                ...rootInputStyles
              },
            }}
            inputProps={{
              sx: {
                ...inputStyles
              },
            }}
            FormHelperTextProps={{
              sx: {
                ...helperTextStyles
              },
            }}

          />
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            sx={{ ...rootStyles }}
            InputLabelProps={{
              sx: {
                ...inputLabelStyles
              },
            }}
            InputProps={{
              sx: {
                ...rootInputStyles
              },
            }}
            inputProps={{
              sx: {
                ...inputStyles
              },
            }}
            FormHelperTextProps={{
              sx: {
                ...helperTextStyles
              },
            }}
          />
          { /*
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          */ }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ 
              mt: 0, 
              mb: 1,
              pt: 3, 
              pb: 3,
              typography: {
                fontWeight: 700,
                fontSize: '1.4em',
                color: variables.quizic_background,
                lineHeight: '1.4em',
              },
            }} 
            disableElevation
          >
            Sign In
          </Button>
          <Grid container spacing={2}>
            <Grid item xs>
              <Button onClick={()=>handleLink('/passwordreset')}
                fullWidth
                variant="contained"
                sx={{ 
                  mt: 2, 
                  mb: 1,
                  pt: 2, 
                  pb: 2,
                  typography: {
                    fontWeight: 700,
                    color: variables.quizic_background,
                    lineHeight: '1.4em',
                  },
                  backgroundColor: '#999',
                }} 
                disableElevation>
                {"Forgot your password?"}
              </Button>
            </Grid>
            <Grid item xs 
              sx={{mt:0}}>
              <Button onClick={()=>handleLink('/signup')}
                fullWidth
                variant="contained"
                sx={{ 
                  mt: 2, 
                  mb: 1,
                  pt: 2, 
                  pb: 2,
                  typography: {
                    fontWeight: 700,
                    color: variables.quizic_background,
                    lineHeight: '1.4em',
                  },
                  backgroundColor: '#999',
                }} 
                disableElevation>
                No account?<br />Sign Up
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    : null }
    </div>
  );
}