import React from 'react'

const JoinEventPage: React.FC = () => {
    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h1 style={{ fontSize: '4em' }}>Join Event</h1>
        </div>
    )
}

export default JoinEventPage
