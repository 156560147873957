import React from 'react'
import { createSearchParams, Link, useNavigate, } from 'react-router-dom'
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { setUserId, setTeamName } from '../redux/App/app.actions'
import store from '../redux/store'
import homepage from './homepage.scss'
import { useState, useEffect } from 'react'
import { TextField, Button } from '@mui/material';
import logo from '../images/quizic1.png'; 


const HomePage = () => {

    const [formTeamName, setFormTeamName] = useState("");
    const [showGameLink, setShowGameLink] = useState(false);

    const navigate = useNavigate()

    /*
    const auth = getAuth();
    signInAnonymously(auth)
      .then((a) => {
        store.dispatch( setUserId(a.user.uid) );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });

    const handleSubmit = (e) => {
        console.log("CALLING REDUX", formTeamName)
        store.dispatch( setTeamName(formTeamName) );

        navigate('/game/0elwnjhyAMOtbWhTrtfxoyJnzsJ2/1')

        //setShowGameLink(true)
    }
    */

/*onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
        console.log("USER ID",uid)
    // ...
  } else {
    // User is signed out
    // ...
  }
});*/



    return (
        <div className="homepage">
            <img src={logo} style={{opacity:"20%",marginBottom:"0px"}} className="logo" />
            <h6 style={{marginBottom:"1em",marginTop:"0px"}}>Welcome to Soundcheck Live</h6>
        </div>
    )
}

export default HomePage


/*
                  <input
                    name='formTeamName'
                    type='text'
                    onChange={e => setFormTeamName(e.target.value)}
                    />
                <button type="button" onClick={(e)=>handleSubmit()}>Set Team Name</button>
*/
