import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { setError } from '../redux/App/app.actions'
import { connect, useDispatch } from 'react-redux'

const mapStateToProps = (state) => ({
  errorMessage: state.app.errorMessage,
  errorCallback: state.app.errorCallback,
})


const ErrorMessage = ({errorMessage, errorCallback}) => {

  const dispatch = useDispatch();

  const handleCallback = () => {
    errorCallback()
    dispatch(setError("",()=>{}))
  };

  const handleClose = () => {
    dispatch(setError("",()=>{}))
  };

  return (
    <div>
      <Dialog
        open={errorMessage !== ""}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {errorMessage}
        </DialogTitle>
        { /* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent> */ }
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
          {typeof errorCallback === 'function' ? 
            <Button onClick={handleCallback} autoFocus>
              Go
            </Button> : null}

        </DialogActions>
      </Dialog>
    </div>
  );
}


export default connect(mapStateToProps)(ErrorMessage)