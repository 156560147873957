import React from 'react'
import { HashRouter, BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AdminRoute from './pages/AdminRoute'
import GameRoute from './pages/GameRoute'
import HomePage from './pages/HomePage'
import SignInPage from './pages/SignInPage'
import SignUpPage from './pages/SignUpPage'
import SignOutPage from './pages/SignOutPage'
import PasswordResetPage from './pages/PasswordResetPage'
import SetTeamNamePage from './pages/SetTeamNamePage'
import JoinEventPage from './pages/JoinEventPage'
import EventPage from './pages/EventPage'
import GamePage from './pages/GamePage'
import GamePageDisplay from './pages/GamePageDisplay'
import EventAdminPage from './pages/EventAdminPage'
import TestPage from './pages/TestPage'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import { connect } from 'react-redux'
import './styles/main.sass'
import { useState, useEffect } from 'react'
import store from './redux/store'
import { setTeamName } from './redux/App/app.actions'
import Menu from './components/Menu'

/*
http://localhost:3000/#/eventadmin/0elwnjhyAMOtbWhTrtfxoyJnzsJ2/1
*/

const mapStateToProps = (state) => ({
  userId:           state.app.userId,
  teamName:         state.app.teamName,
})

const RootComponent = ({ userId, teamName }) => {

    const [teamNameLS, setTeamNameLS] = useState(()=>{
        // Load team name from local storage if it exists and put it into teamName variable
        const saved = localStorage.getItem("teamName")
        store.dispatch( setTeamName(saved) );
        return saved || ""
    })

    return (
        <HashRouter>
            <Menu />
            <Routes>
                <Route exact path="*" element={<NotFoundPage />} />
                <Route exact path={ROUTES.SIGNIN_PAGE} element={<SignInPage teamName={teamName} />} />
                <Route exact path={ROUTES.SIGNUP_PAGE} element={<SignUpPage />} />
                <Route exact path={ROUTES.SIGNOUT_PAGE} element={<SignOutPage />} />
                <Route exact path={ROUTES.PASSWORD_RESET} element={<PasswordResetPage />} />
                <Route exact path={ROUTES.SET_TEAM_NAME_PAGE} element={<SetTeamNamePage />} />
                <Route element={<AdminRoute 
                                    isAllowed       = { userId /* IS THIS USER LOGGED IN?AND (possibly) DO THEY OWN THE PATH? */} 
                                />}>
                    <Route exact path={ROUTES.HOME_PAGE} element={<HomePage />} />
                    <Route exact path={ROUTES.EVENT_ADMIN_PAGE + "/:eventOwnerId/:gameId"} element={<EventAdminPage />} />
                    <Route exact path={ROUTES.GAME_DISPLAY_PAGE + "/:eventOwnerId/:gameId"} element={<GamePageDisplay />} />
                </Route>
                <Route element={<GameRoute 
                                    isAllowed       = { userId /* IS THIS USER LOGGED IN? */} 
                                    isTeamNameSet   = { teamName /* IS TEAM NAME SET? */} 
                                />}>
                    <Route exact path={ROUTES.JOIN_EVENT} element={<JoinEventPage />} />
                    <Route exact path={ROUTES.EVENT_PAGE + "/:eventOwnerId/:eventId"} element={<EventPage />} />
                    <Route exact path={ROUTES.GAME_PAGE + "/:eventOwnerId/:gameId"} element={<GamePage />} />
                </Route>
                <Route exact path={ROUTES.TEST_PAGE} element={<TestPage />} />
            </Routes>
        </HashRouter>
    )
}

export default connect(mapStateToProps)(RootComponent)
