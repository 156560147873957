import React from 'react'
import { useState } from 'react'
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import passwordreset from "./passwordreset.scss"
import { setUserId } from '../redux/App/app.actions'
import store from '../redux/store'
import { setError } from '../redux/App/app.actions'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import variables from '../styles/main.sass'


export default function PasswordResetPage() {

  const navigate = useNavigate()

  const { state } = useLocation(); // state is any or unknown

  const destination = state?.destination ?? '/';

  const auth = getAuth()


  const handleLink = (link) => {
    navigate(link,{ state: {destination: destination}}) 
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    if (/^[^@\s]+@[^@\s]+\.[a-z]{2,3}$/.test(data.get('email'))) {
      sendPasswordResetEmail(auth, data.get("email"), data.get("password"))
      .then(() => {
        // EMAIL SENT
        store.dispatch(setError("A password reset email has been sent. Follow the directions in the email and then sign in again.", ()=>navigate('/signin',{ state: {destination: destination}})))
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        store.dispatch(setError("Something went wrong. Please try again." + errorMessage))
      })
    } else {
      store.dispatch(setError("Something went wrong. Likely the email is invalid or the password you gave is less than 6 characters. Please check your form and try again."))
    }
  }


  /* THESE SHOULD BE MOVED UP TO ABSTRACT */
  const rootStyles = {
    border: `2px solid ${variables.quizic_correct}`,
    borderRadius: 2,
    '& label.Mui-focused': {
      color: variables.quizic_correct,
    },
    marginBottom: '20px'
  };

  const inputLabelStyles = {
    color: variables.quizic_correct,
    textTransform: 'capitalize',
    backgroundColor: variables.quizic_background,
    fontWeight: '700',
    paddingLeft: '6px', 
    paddingRight: '6px', 
  };

  const rootInputStyles = {
    '&:hover fieldset': {
    },
    '&:focus-within fieldset, &:focus-visible fieldset': {
      border: 'none',
    },
  };

  const inputStyles = {
    color: 'white',
    paddingLeft: '15px',
    fontSize: '20px',
  };

  const helperTextStyles = {
    color: 'blue',
  };

  return (
    <div className='passwordreset'>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{  
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4">
          Password Reset
        </Typography>
        <Typography 
          component="p"
          sx={{
            margin:'10px',
            lineHeight: '1.6em'}}>
          Enter your email address and a link will be sent to your inbox with instructions on resetting your password.
        </Typography>
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          noValidate 
          sx={{ 
            mt: 1, 
            padding: '10px', 
          }}
          >
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            sx={{ ...rootStyles }}
            InputLabelProps={{
              sx: {
                ...inputLabelStyles
              },
            }}
            InputProps={{
              sx: {
                ...rootInputStyles
              },
            }}
            inputProps={{
              sx: {
                ...inputStyles
              },
            }}
            FormHelperTextProps={{
              sx: {
                ...helperTextStyles
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ 
              mt: 0, 
              mb: 1,
              pt: 3, 
              pb: 3,
              typography: {
                fontWeight: 700,
                fontSize: '1.4em',
                color: variables.quizic_background,
                lineHeight: '1.4em',
              },
            }} 
            disableElevation
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item xs>
              <Button onClick={()=>handleLink('/signup')}
                fullWidth
                variant="contained"
                sx={{ 
                  mt: 2, 
                  mb: 1,
                  pt: 2, 
                  pb: 2,
                  typography: {
                    fontWeight: 700,
                    color: variables.quizic_background,
                    lineHeight: '1.4em',
                  },
                  backgroundColor: '#999',
                }} 
                disableElevation>
                {"Don't have an account? Sign Up"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    </div>
  );
}