import React from 'react'
import { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { triggerGetReady, getQuestionOptionsList, setActiveQuestion, changeMessage, resetGame } from '../utility/firebase'
import { useNavigate, useParams } from 'react-router-dom'
import logo from '../images/quizic1.png'; 
import eventadminpage from './eventadminpage.scss'
import { TextField, Button } from '@mui/material';
import { setError } from '../redux/App/app.actions'

const mapStateToProps = (state) => ({
  //eventOwnerId:     state.app.eventOwnerId,
  app:                  state.app,
  activeQuestion:       state.game.activeQuestion,
  userId:               state.app.userId,
  availableQuestions:   state.event.availableQuestions,
})



//eventOwnerId: 0elwnjhyAMOtbWhTrtfxoyJnzsJ2 gameId:1

//http://localhost:3000/eventadmin/0elwnjhyAMOtbWhTrtfxoyJnzsJ/1
const EventAdminPage = (props) => {

    const [ showButtons, setShowButtons ] = useState(true);
    const [ activeQuestion, setActivequestion ] = useState("");
    const [ optionsArr, setOptionsArr ] = useState([]);
    const { eventOwnerId, gameId } = useParams()
    const [ queue, setQueue ] = useState([])
    const [ search, setSearch ] = useState("")
    const [ currentContext, setCurrentContext ] = useState("")

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const destination = `/eventadmin/${eventOwnerId}/${gameId}`;

    let optionsArrList = []

    useEffect(()=>{

        getQuestionOptionsList(eventOwnerId);


    },[])

    let contextArr = props.availableQuestions.map((obj)=>obj.context)

    contextArr = [...new Set(contextArr)];


    useEffect(()=>{

        //if search term is two letters or more
        // check if
        let matchcount = 0;
        let match = "";

        if (contextArr) {

            console.log("got here", search)

            contextArr.forEach((item)=>{
                if (item.toLowerCase().includes(search.toLowerCase())) {
                    matchcount++;
                    match = item;
                }   
            })

        }
        if (queue.length < 1) {
            ((matchcount < 2) && (matchcount > 0)) ? setCurrentContext(match) : setCurrentContext("");
        }


    },[search])


    const testArr=[{"context": "name"}, {"context": "nameB"}];

    const handleOptionClick = () => {

        if (!queue.includes(event.srcElement.id)) {
            setQueue(prevState => [...prevState, event.srcElement.id])
        }

        document.getElementById(event.srcElement.id).classList.add("disable")

        let newContext = props.availableQuestions.filter(function (el) {
            return el.index == event.srcElement.id
            }
        )[0].context;
        setCurrentContext(newContext)
    }


    const handleQueueClear = (currentQueue) => {

        queue.forEach((_id)=>{
            document.getElementById(_id).classList.remove("disable")
        })
        setQueue([]);
        setCurrentContext("")

    }

    const handleOfferCongrats = () => {
        changeMessage(true, eventOwnerId, gameId, "WINNER", "TITLEgoeshere", "BODYgoeshere")
    }

    const handleResetGame = () => {
        changeMessage(true, eventOwnerId, gameId, "GENERIC", "", "")
        resetGame(eventOwnerId, gameId)
    }
    const handleFireQuestions = () => {

        // set through questionQueue one by one and set timers 
        // for each question seperated by "none" and 60(?) seconds
        let secondsBetween = 45;
        setShowButtons(false)
        queue.forEach((id, index)=>{
            document.getElementById(id).classList.add("done")
            if (index == 0) {
                // SET GET READY TO TRUE
                triggerGetReady(eventOwnerId, gameId, true)
                setTimeout(()=>{
                    setActiveQuestion(eventOwnerId,gameId,id)
                    setQueue(prevState => [...prevState.filter(item => item !== id)])
                }, 10000) 
                setTimeout(()=>{
                    // SET GET READY TO FALSE
                    triggerGetReady(eventOwnerId, gameId, false)
                    //setActiveQuestion(eventOwnerId,gameId,"none")
                }, 12000) 
            } else {
                // SET GET READY TO TRUE
                triggerGetReady(eventOwnerId, gameId, true)
                setTimeout(()=>{
                    setActiveQuestion(eventOwnerId,gameId,id)
                    setQueue(prevState => [...prevState.filter(item => item !== id)])
                }, index * 60000 + 10000) 
                setTimeout(()=>{
                    // SET GET READY TO FALSE
                    triggerGetReady(eventOwnerId, gameId, false)
                    //setActiveQuestion(eventOwnerId,gameId,"none")
                    //console.log("FIRING NONE")
                    setShowButtons(true)
                }, index * 60000 + 12000) 
            }
            //setActiveQuestion(eventOwnerId,gameId,event.srcElement.id)


        })
        setCurrentContext("")
        setShowButtons(true)

        //setActiveQuestion(eventOwnerId,gameId,event.srcElement.id)
        //setTimeout(()=>setActiveQuestion(eventOwnerId,gameId,"none"), 5000)
    }


    function getRandColor(brightness){

        // Six levels of brightness from 0 to 5, 0 being the darkest
        var rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
        var mix = [brightness*51, brightness*51, brightness*51]; //51 => 255/5
        var mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(function(x){ return Math.round(x/2.0)})
        return "rgb(" + mixedrgb.join(",") + ")";
    }

    useEffect(()=> {
        
        if (props.availableQuestions.length > 0) { 

            let index = 0 
            let lastContext
            let currentColor
            let sortedAvailableQuestions = props.availableQuestions.sort((a,b) => (a.context > b.context) ? 1 : ((b.context > a.context) ? -1 : 0))

            setOptionsArr(
                props.availableQuestions.map((qObj) => {

                    if (lastContext !== qObj.context) {
                        currentColor = getRandColor(0)
                        lastContext = qObj.context
                    }

                    index++
                    return (


                        /* 
                            Logic for display, if this context (qObj.context) matches 
                            the context of the first element in the queue's context OR if 
                            queue length is zero, display, otherwise hide

                            style={dispimport React, { useRef } from "react";
lay: {qObj.context}}


                        className={`question-option ${qObj.index == props.availableQuestions[qObj.index] ? 'null' : 'notnull'}`} */
                    <Button 
                        className={`question-option 
                            ${( (qObj.context == currentContext) || (currentContext == "") && (!queue.includes(qObj.index.toString) )) ? 'show' : "hide"}
                        `}
                        id={qObj.index} style={{backgroundColor:currentColor}} onClick={(e)=>handleOptionClick(e)} key={index}>
                        <h2 id={qObj.index}>{qObj.context}</h2>
                        <p id={qObj.index}>{qObj.question}</p>
                        {/*
                        <p id={qObj.index}>INDEX: {JSON.stringify( qObj.index )}</p>

                        <p id={qObj.index}>{JSON.stringify( props.availableQuestions[qObj.index] )}</p>
                        <p id={qObj.index}>{qObj.index == props.availableQuestions[qObj.index] ? 'null' : 'notnull'}</p>
                        */}
                    </Button>)
                })
            )

        }
    },[props.availableQuestions, currentContext])


    console.log("TEST", testArr, testArr.length)

    useEffect(()=>{
        eventOwnerId !== props.userId ? dispatch(setError("You are not the event owner. Sign out and re-sign in.", ()=>navigate('/signout', {state: {destination: destination }}))) : null;
    },[])


    return (
        <div className="eventadminpage">
        { eventOwnerId === props.userId ? <div>
            <div className="searchContainer">
                <TextField style={{backgroundColor: '#fff', minWidth:'300px', borderRadius:'6px'}} id="formTeamName" label="Search Song" variant="filled" 
                    //inputRef={input => input && input.focus()}

                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)
                    } value={search} />
            </div>

            <Button className="post" onClick={()=>handleOfferCongrats()}>OFFER CONGRATS</Button>
            <Button className="post" onClick={()=>handleResetGame()}>CLEAR GAME</Button>

            {currentContext !=="" ? 
            <div className="controls">
                { showButtons ? <div>
                    <Button className="post" onClick={()=>handleFireQuestions()}>POST QUESTIONS</Button>
                    <Button className="clear" onClick={()=>handleQueueClear()}>CLEAR QUEUE</Button>
                    </div>
                : null 
                }   
                { <p className="queuelist"><b>{queue.length} x {currentContext}</b></p>  }
            </div>
            :
            <>
            </>
            }
            {optionsArr} </div> : <p></p> }
        </div>
    );

}

export default connect(mapStateToProps)(EventAdminPage)