import React from 'react'
import { createSearchParams, Link, useNavigate, useLocation, } from 'react-router-dom'
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { setUserId, setTeamName } from '../redux/App/app.actions'
import store from '../redux/store'
import setteamnamepage from './setteamnamepage.scss'
import { useState, useEffect } from 'react'
import { TextField, Button } from '@mui/material';
import logo from '../images/quizic1.png'; 
import variables from '../styles/main.sass'
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";


const SetTeamNamePage = () => {

    const { state } = useLocation(); // state is any or unknown

    const destination = state?.destination ?? '/';

    const [formTeamName, setFormTeamName] = useState(() => {
      const saved = localStorage.getItem("teamName");
      return saved || "";
    });
    const [showGameLink, setShowGameLink] = useState(false);




    const navigate = useNavigate()

    /*
    const auth = getAuth();
    signInAnonymously(auth)
      .then((a) => {
        console.log("SIGNED IN!",a.user.uid)
        console.log("SIGNED IN ALSO!",a.user.uid)
        store.dispatch( setUserId(a.user.uid) );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
    */

    const handleSubmit = (e) => {
        //if (formTeamName.length > 0) {
            store.dispatch( setTeamName(formTeamName) );
            localStorage.setItem("teamName", formTeamName)
            navigate(destination)
        //} else {
            console.log("team name blank")
        //}
        //setShowGameLink(true)
    }
    
/*onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
        console.log("USER ID",uid)
    // ...
  } else {
    // User is signed out
    // ...
  }
});*/



  /* THESE SHOULD BE MOVED UP TO ABSTRACT */
  const rootStyles = {
    border: `2px solid ${variables.quizic_correct}`,
    borderRadius: 2,
    '& label.Mui-focused': {
      color: variables.quizic_correct,
    },
    marginBottom: '20px'
  };

  const inputLabelStyles = {
    color: variables.quizic_correct,
    textTransform: 'capitalize',
    backgroundColor: variables.quizic_background,
    fontWeight: '700',
    paddingLeft: '6px', 
    paddingRight: '6px', 
  };

  const rootInputStyles = {
    '&:hover fieldset': {
    },
    '&:focus-within fieldset, &:focus-visible fieldset': {
      border: 'none',
    },
  };

  const inputStyles = {
    color: 'white',
    paddingLeft: '15px',
    fontSize: '20px',
  };

  const helperTextStyles = {
    color: 'blue',
  };

    return (
        <div className="homepage">
            { !showGameLink?     <Container component="main" maxWidth="xs">
      <Box
        sx={{  
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
<form onSubmit={(e)=>handleSubmit()}>
                    <img src={logo} style={{opacity:"20%",marginBottom:"0px"}} className="logo" />
                    <h6 style={{marginBottom:"1em",marginTop:"0px"}}>Welcome to <nobr>Soundcheck-Live</nobr></h6>
                    <p>Set your team&lsquo;s name to begin.</p>
                    <TextField 
                        fullWidth
                        sx={{ ...rootStyles }}
                        InputLabelProps={{
                          sx: {
                            ...inputLabelStyles
                          },
                        }}
                        InputProps={{
                          sx: {
                            ...rootInputStyles
                          },
                        }}
                        inputProps={{
                          sx: {
                            ...inputStyles
                          },
                        }}
                        FormHelperTextProps={{
                          sx: {
                            ...helperTextStyles
                          },
                        }}

                        id="formTeamName" 
                        label="Team Name" 
                        onChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => setFormTeamName(event.target.value)
                        } value={formTeamName} />
                        <br />
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{ 
                          mb: 1,
                          pt: 2, 
                          pb: 2,
                          typography: {
                            fontWeight: 700,
                            color: variables.quizic_background,
                            lineHeight: '1.4em',
                          },
                          backgroundColor: variables.quizic_correct,
                        }} 
                        disableElevation /*disabled={formTeamName==""}*/
                        onClick={(e)=>handleSubmit()}>
                            Set Team Name
                        </Button>
                <br/>
            </form></Box></Container> : null}

            { showGameLink? <h1><Link to="/game/0elwnjhyAMOtbWhTrtfxoyJnzsJ2/1">GO TO GAME</Link></h1> : null }

        </div>
    )
}

export default SetTeamNamePage


/*
                  <input
                    name='formTeamName'
                    type='text'
                    onChange={e => setFormTeamName(e.target.value)}
                    />
                <button type="button" onClick={(e)=>handleSubmit()}>Set Team Name</button>
*/
