import React from 'react'
import './countdown.scss';


const Countdown = ({count}) => {

    return (<h1 className="countdown">{ 10-count }</h1>)

}

export default React.memo(Countdown)