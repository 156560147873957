import React from 'react'
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import signup from "./signinpage.scss"
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { setUserId, setError } from '../redux/App/app.actions'
import store from '../redux/store'
import variables from '../styles/main.sass'

export default function SignUpPage() {

  const navigate = useNavigate()

  const { state } = useLocation(); // state is any or unknown

  const destination = state?.destination ?? '/';

  const auth = getAuth()

  const handleLink = (link) => {
    navigate(link,{ state: {destination: destination}}) 
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = new FormData(event.currentTarget);

    if ((/^[^@\s]+@[^@\s]+\.[a-z]{2,3}$/.test(data.get('email')) && (data.get('password').length > 5))) {
      createUserWithEmailAndPassword(auth, data.get("email"), data.get("password"))
      //firebase.auth().createUserWithEmailAndPassword(data.get("email"), data.get("password"))
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        store.dispatch( setUserId(user.uid) );
        navigate(destination)
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;

        if (errorCode === "auth/email-already-in-use") {
          store.dispatch(setError("That email is already in use. Please try another, or hit 'GO' to reset your password.", ()=>navigate('/passwordreset',{ state: {destination: destination}})))
        }
        if (errorCode === "auth/weak-password") {
          store.dispatch(setError("Password must be at least six (6) characters."))
        }
        console.log("SIGN UP ERROR!!!!!!", errorMessage, errorCode)
        // ..
      });

    } else {
      store.dispatch(setError("Password must be at least six (6) characters and emails must be valid emails."))
    }
  };

  /* THESE SHOULD BE MOVED UP TO ABSTRACT */
  const rootStyles = {
    border: `2px solid ${variables.quizic_correct}`,
    borderRadius: 2,
    '& label.Mui-focused': {
      color: variables.quizic_correct,
    },
    marginBottom: '20px'
  };

  const inputLabelStyles = {
    color: variables.quizic_correct,
    textTransform: 'capitalize',
    backgroundColor: variables.quizic_background,
    fontWeight: '700',
    paddingLeft: '6px', 
    paddingRight: '6px', 
  };

  const rootInputStyles = {
    '&:hover fieldset': {
    },
    '&:focus-within fieldset, &:focus-visible fieldset': {
      border: 'none',
    },
  };

  const inputStyles = {
    color: 'white',
    paddingLeft: '15px',
    fontSize: '20px',
  };

  const helperTextStyles = {
    color: 'blue',
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{  
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4">
          Sign Up
        </Typography>
        <Typography 
          component="p"
          sx={{
            margin:'10px',
            lineHeight: '1.6em'}}>
          You must create an account to play Soundcheck-Live. Simply fill in the form below and you will be on your way!
        </Typography>
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          noValidate 
          sx={{ 
            mt: 1, 
            padding: '10px', 
          }}
          >
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            sx={{ ...rootStyles }}
            InputLabelProps={{
              sx: {
                ...inputLabelStyles
              },
            }}
            InputProps={{
              sx: {
                ...rootInputStyles
              },
            }}
            inputProps={{
              sx: {
                ...inputStyles
              },
            }}
            FormHelperTextProps={{
              sx: {
                ...helperTextStyles
              },
            }}

          />
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            sx={{ ...rootStyles }}
            InputLabelProps={{
              sx: {
                ...inputLabelStyles
              },
            }}
            InputProps={{
              sx: {
                ...rootInputStyles
              },
            }}
            inputProps={{
              sx: {
                ...inputStyles
              },
            }}
            FormHelperTextProps={{
              sx: {
                ...helperTextStyles
              },
            }}

          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ 
              mt: 0, 
              mb: 1,
              pt: 3, 
              pb: 3,
              typography: {
                fontWeight: 700,
                fontSize: '1.4em',
                color: variables.quizic_background,
                lineHeight: '1.4em',
              },
            }} 
            disableElevation
          >
            Sign Up
          </Button>
            <Grid item xs 
              sx={{mt:0}}>
              <Button onClick={()=>handleLink('/signin')}
                fullWidth
                variant="contained"
                sx={{ 
                  mt: 2, 
                  mb: 1,
                  pt: 2, 
                  pb: 2,
                  typography: {
                    fontWeight: 700,
                    color: variables.quizic_background,
                    lineHeight: '1.4em',
                  },
                  backgroundColor: '#999',
                }} 
                disableElevation>
                Already have an account?<br />Sign In
              </Button>
            </Grid>
        </Box>
      </Box>
    </Container>
  );
}