// Game reducer

import { 
  START_QUESTION,
  END_QUESTION,
  UPDATE_PLAYERS_LIST,
  UPDATE_SCORE,
  GET_READY,
  SET_MESSAGE
} from './game.types'

const INITIAL_STATE = {
  activeQuestion: '',
  question: '',
  answer: '',
  w1: '',
  w2: '',
  w3: '',
  order: '0123',
  playersList: [],
  score: 0,
  startAt:0, // startAt stores the server offset adjusted start time
  getready: false,
  message: null,
};

const gameReducer = (state = INITIAL_STATE, action) => {

    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {

      case START_QUESTION:
          return { 
            ...updatedState, 
            activeQuestion: action.id,
            question: action.question,
            answer: action.answer,
            w1: action.w1,
            w2: action.w2,
            w3: action.w3,
            order: action.order,
            startAt: action.startAt,
          };

      case END_QUESTION:
          return { 
            ...updatedState, 
          };      

      case UPDATE_PLAYERS_LIST:
          return { 
            ...updatedState, 
            playersList: action.playersList
          };      

      case UPDATE_SCORE:
          return { 
            ...updatedState, 
            score: action.score
          };      

      case GET_READY:
          return { 
            ...updatedState, 
            getready: action.status
          };      

      case SET_MESSAGE:
          return { 
            ...updatedState, 
            message: action.message
          };

      default: return state;
      
    }

};

export default gameReducer;