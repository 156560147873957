import React, { useEffect, useState, useMemo } from 'react'
import './question.scss';
import { ANSWERKEYS } from '../resources/answerkeys-constants'
import { endQuestion } from '../redux/Game/game.actions'
import store from '../redux/store'
import TeamScoreView from '../components/TeamScoreView'
import Countdown from '../components/Countdown'
import useStartAtTimer from '../hooks/useStartAtTimer'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';


const QUESTION_VIEWS = {
    COUNTDOWN:    'COUNTDOWN',
    HEREWEGO:     'HEREWEGO',
    QUESTION:     'QUESTION',
    ELABORATION:  'ELABORATION',
    YOURSCORE:    'YOURSCORE',
    BLANK:        'BLANK',
    LEADERBOARD:  'LEADERBOARD'
}

const Question = (props) => {

    const {qid, question, answer, w1, w2, w3, order, elaboration, playersList, startAt} = props;
    const [counter, setCounter] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [potentialScore, setPotentialScore] = useState(0);
    const [answerAddClasses, setAnswerAddClasses] = useState({
        0: "",
        1: "",
        2: "",
        3: ""
    });
    const [questionView, setQuestionView] = useState("LEADERBOARD");
    const [showAnswers, setShowAnswers] = useState(false);
    const [score, setScore] = useState(0);
    const [determinatePercent, setDeterminatePercent] = useState(-1000);
    const [hook_timeElapsed, hook_resetTimer] = useStartAtTimer()
    const questionObj = {question, answer, w1, w2, w3, order};

    const orderArr = order.split("");

    const handleClick = (index) => {

        if (!answerAddClasses[index].includes(' dim')) {

            answerAddClasses[0] = answerAddClasses[0].replace(' selected','');
            answerAddClasses[1] = answerAddClasses[1].replace(' selected','');
            answerAddClasses[2] = answerAddClasses[2].replace(' selected','');
            answerAddClasses[3] = answerAddClasses[3].replace(' selected','');
            answerAddClasses[index] = answerAddClasses[index] + " selected";

            index == '0' ? (()=>{
                setScore(potentialScore);
            })() : setScore(-1);

        }

    }

    let timer;


    useEffect(() => {

        setScore(0);
        hook_resetTimer(props.startAt)

    },[qid] )


    useEffect(() => {
        if (determinatePercent > 0) {
            setDeterminatePercent(determinatePercent - 26);
        }
        setPotentialScore((36-hook_timeElapsed)*20)
        switch (hook_timeElapsed) {
            case 0: 
                setQuestionView(QUESTION_VIEWS.LEADERBOARD);
                break;
            case 1: 
                setQuestionView(QUESTION_VIEWS.COUNTDOWN);
                break;
            case 11: 
                setQuestionView(QUESTION_VIEWS.HEREWEGO);
                break;
            case 16: 
                setQuestionView(QUESTION_VIEWS.QUESTION);
                break;
            case 17:
                setShowAnswers(true);
                break;
            case 19:
                setDeterminatePercent(100);
                break;
            case 23:
                setDeterminatePercent(100);
                answerAddClasses[3] = answerAddClasses[3].replace(' selected','') + " dim";
                break;
            case 27:
                setDeterminatePercent(100);
                answerAddClasses[2] = answerAddClasses[2].replace(' selected','') + " dim";
                break;
            case 31:
                answerAddClasses[1] = answerAddClasses[1].replace(' selected','') + " dim";
                break;
            case 36:
                answerAddClasses[0] = answerAddClasses[0].replace(' selected','') + " blink";
                break;
            case 40:
                if (score > -1) {
                    props.handleScore(score)
                } else {
                    props.handleScore(0);
                    setScore(0);
                }

                setQuestionView(QUESTION_VIEWS.ELABORATION)
                setShowAnswers(false);
                /*
                elaboration !== "" && elaboration ? 
                    (()=>{
                        setQuestionView(QUESTION_VIEWS.ELABORATION)
                        setShowAnswers(false);
                    })() : 
                    null;
                */
                break;
            case 45:
                setScore(0);
                setAnswerAddClasses({
                    0: "",
                    1: "",
                    2: "",
                    3: ""
                });
                store.dispatch( endQuestion() );
                setQuestionView(QUESTION_VIEWS.LEADERBOARD);
                break;
        }
    }, [hook_timeElapsed]);


    const HereWeGoView = () => {
        return (
            <h1 className="herewego">HERE WE GO</h1>
        )
    }


    const LeaderboardView = (playersList) => {

        function createData(
          teamname: string,
          score: number,
        ) {
          return { teamname, score };
        }

        return (
            <div className="leaderboard">
                  <Table>
                    <TableHead>
                      <TableRow className="top-row">
                        <TableCell>Team</TableCell>
                        <TableCell align="right">Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {props.playersList.sort((b,a) => (a.score > b.score) ? 1 : ((b.score > a.score) ? -1 : 0)).map((row) => (
                        <TableRow key={row.playerId} className="row">
                          <TableCell className="teamname">
                            {row.teamname}
                          </TableCell>
                          <TableCell className="score">{row.score}</TableCell>
                        </TableRow>
                      ))}

                    </TableBody>
                  </Table>
            </div>
        )
    }



    const QuestionView = () => {

       return (
            <div className="questionpage" >
                <h1 className='question'>{question}</h1>
                <div className='answers'>
                { showAnswers ? orderArr.map((index)=> {
                        return (
                            <div id={`answer-${index}`} onClick={()=>handleClick(index)} key={index} className={`answer ${answerAddClasses[index]}`}>{questionObj[ANSWERKEYS[index]]}</div>
                        );
                    }):null
                }
                <p className="testingonly">COUNTER: {counter} | POTENTIAL: {potentialScore} | Actual: {score}</p>
                    {determinatePercent > 0 
                    ?
                    <div className="determinate-bar-container">
                    <LinearProgress
                        size="lg"
                        value={determinatePercent}
                        variant="determinate" 
                    >
                        NEXT CLUE
                    </LinearProgress></div> :null}
                </div>
            </div>
        )
    }



    switch (process.env.REACT_APP_TESTING == "true" ? "TESTING" : questionView) {

        case "TESTING":
            return <TeamScoreView score={score} elaboration={elaboration}/>

        case QUESTION_VIEWS.COUNTDOWN:
            return <Countdown count={hook_timeElapsed-1} />

        case QUESTION_VIEWS.HEREWEGO:
            return <div className='questionpage'><HereWeGoView/></div>

        case QUESTION_VIEWS.BLANK:
            return <><p>BLANK</p></>

        case QUESTION_VIEWS.QUESTION:
            return <div className='questionpage'><QuestionView/></div>

        case QUESTION_VIEWS.ELABORATION:
            return <TeamScoreView score={score} elaboration={elaboration}/>

        case QUESTION_VIEWS.LEADERBOARD:
            return <LeaderboardView playersList={props.playersList}/>

        default: 
            return <></>
    }

}

export default Question
