import React from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { resetGame } from '../utility/firebase'
import gamepage from './testpage.scss'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import qr from '../images/qr.jpg'; 

const mapStateToProps = (state) => ({
  //eventOwnerId:     state.app.eventOwnerId,
  app:           state.app,
  userId:           state.app.userId,
  //activeGame:       state.event.activeGame,
  activeQuestion:   state.game.activeQuestion,
  question:         state.game.question,
  answer:           state.game.answer,
  w1:               state.game.w1,
  w2:               state.game.w2,
  w3:               state.game.w3,
  order:            state.game.order,
  teamName:         state.app.teamName,
  playersList:      state.game.playersList,
})




const TestPage = (props) => {

    const navigate = useNavigate()

    const resetGame = (eventOwnerId, gameId) => {

        resetGame(eventOwnerId, gameId);

    }

    return (
        <div className='testpage'>
            <h1>TEST MANAGEMENT PAGE</h1>
            <button onClick={()=>resetGame("0elwnjhyAMOtbWhTrtfxoyJnzsJ2","1")} >RESET GAME</button>
            <br />
            <br />
            <Link to="/eventadmin/0elwnjhyAMOtbWhTrtfxoyJnzsJ2/1">GO TO GAME ADMIN</Link> 
            <br />
            <br />
            <br />
            <br />
            <br />
            <img src={qr} /><br />
        </div>
    );


}

export default connect(mapStateToProps)(TestPage)