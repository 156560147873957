import React from 'react'
import { setupEventListener } from '../utility/firebase'
import { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const mapStateToProps = (state) => ({
  //eventOwnerId:     state.app.eventOwnerId,
  userId:           state.app.userId,
  activeGame:       state.event.activeGame,
})


const EventPage = (props) => {

    const { eventOwnerId, eventId } = useParams()

    // Setup Game Listener
    useEffect(()=>{

        const fbEventListener = setupEventListener(eventOwnerId, eventId);

    },[]);

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h1 style={{ fontSize: '4em' }}>Event</h1>
        </div>
    )
}

export default connect(mapStateToProps)(EventPage)