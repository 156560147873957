// Event actions

import { 
	SET_AVAILABLE_QUESTIONS,
	SET_QUESTION_AS_UNAVAILABLE,
	SET_ACTIVE_GAME,
	END_ACTIVE_GAME,
} from './event.types'



export const setQuestionAsUnavailable = (qid) => {

	return {
		type: SET_QUESTION_AS_UNAVAILABLE,
		payload: {
			qid: qid
		}
	};

}

export const setAvailableQuestions = (questionsArr) => {

	return {
		type: SET_AVAILABLE_QUESTIONS,
		payload: {
			questionsArr: questionsArr
		}
	};

}

export const setActiveGame = (gameId) => {

	return {
		type: SET_ACTIVE_GAME,
		payload: gameId
	};

}

export const endGame = (gameId) => {

	return {
		type: END_ACTIVE_GAME
	};

}