import * as React from 'react';
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Slide } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { connect, useDispatch } from 'react-redux'
import './menu.scss';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import AlarmIcon from '@mui/icons-material/Alarm';
//import { Link } from "react-router-dom";
import { useNavigate, Navigate, Outlet, useParams, useLocation } from 'react-router-dom';

const mapStateToProps = (state) => ({
  userId: state.app.userId,
})


const Menu = (props) => {

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const navigate = useNavigate()
  const { state } = useLocation(); // state is any or unknown
  const destination = state?.destination ?? '/';


  const handleOpen = () => {
    console.log("MENU CLICKED");
    setMenuIsOpen(true);
  }

  const handleClose = () => {
    setMenuIsOpen(false);
  }

  const handleSignOut = () => {
    setMenuIsOpen(false);
  }

  const handleLink = (link) => {

    setMenuIsOpen(false)

    switch (link) {
      
      case "SIGNOUT":
        navigate('/signout',{state: {destination: destination}})
        break

      case "SIGNIN":
        navigate('/signin',{state: {destination: destination}})
        break

      case "SIGNUP":
        navigate('/signup',{state: {destination: destination}})
        break
    }

  }

  return (
    <div className="menu">
      <Dialog
        open={menuIsOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="menu"
      >
        <DialogTitle id="alert-dialog-title">
            <IconButton aria-label="close" className="menu-close-button" onClick={()=>handleClose()}>
              <CancelIcon fontSize="large" />
            </IconButton>
        </DialogTitle>
        <DialogContent>
          {props.userId !== "" 
            ?
            <ul className="menu-list">
              <li><a onClick={()=>handleLink('SIGNOUT')}>Sign out</a></li>
            </ul>
            :
            <ul className="menu-list">
              <li><a onClick={()=>handleLink('SIGNIN')}>Sign in</a></li>
              <li><a onClick={()=>handleLink('SIGNUP')}>Sign up</a></li>
            </ul>
          }
        </DialogContent>
      </Dialog>
        <div className='menu-icon-container'><MenuIcon onClick={()=>handleOpen()} className="menu-icon"/></div>
    </div>
  );
}


export default connect(mapStateToProps)(Menu)