import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import RootComponent from './RootComponent'
import ErrorMessage from './components/ErrorMessage'
//import { persistor, store } from './store/reducers/store'
import store from './redux/store'
//import { setupFirebaseListener } from './utility/firebase'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import variables from './styles/main.sass'

const App: React.FC = () => {

    const muiTheme = createTheme({
      palette: {
        primary: {
          main: variables.quizic_correct,
        },
      },
    });

    //const fbListener = setupFirebaseListener();
    
    return (
        <ThemeProvider theme={muiTheme}>
            <Provider store={store}>
                <ErrorMessage />
                <RootComponent />
            </Provider>
        </ThemeProvider>
    )
}

export default App
