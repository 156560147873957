import React, { useEffect, useState } from 'react'

export default function useStartAtTimer() {

	const [timeElapsed, setTimeElapsed] = useState(0)
	const [startAtTime, setStartAtTime] = useState(0)

	useEffect(()=>{

        const timer = setInterval(() => {
            const _startAtTime = new Date(startAtTime);
            const _currentTime = new Date();
            const _timeElapsed = Math.round((_currentTime - startAtTime)/1000);
			(_timeElapsed < 46) && (_timeElapsed !== timeElapsed) ? setTimeElapsed(_timeElapsed) : null
        }, 100);

        return () => clearInterval(timer);

	},[startAtTime])

	function resetTimer(time) {
		setTimeElapsed(0)
		setStartAtTime(time)
	}

	return [timeElapsed, resetTimer]
}