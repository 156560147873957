// Game actions

import { 
	START_QUESTION,
	END_QUESTION,
	UPDATE_PLAYERS_LIST,
	UPDATE_SCORE,
	GET_READY,
	SET_MESSAGE,
} from './game.types'



export const startQuestion = (questionId, question, answer, w1, w2, w3, order, startAt) => {

	// Only here until it can be done on the admin side
	function scramble(word) {
		let letters = word.split("");
		let currentIndex = letters.length,
		temporaryValue,
		randomIndex;

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = letters[currentIndex];
		letters[currentIndex] = letters[randomIndex];
		letters[randomIndex] = temporaryValue;
	}

	return letters.join("");
	}


	return {
		type: START_QUESTION,
		id: questionId,
        question: question,
        answer: answer,
        w1: w1,
        w2: w2,
        w3: w3,
        order: scramble("0123"),
        startAt: startAt,
	};

}

export const endQuestion = (questionId) => {

	return {
		type: END_QUESTION,
		id: 'none'
	};

}



export const updatePlayersList = (playersList) => {

	return {
		type: UPDATE_PLAYERS_LIST,
		playersList: playersList
	};

}


export const updateScore = (score) => {

	return {
		type: UPDATE_SCORE,
		score: score
	};

}


export const setGetReady = (status) => {

	return {
		type: GET_READY,
		status: status
	};

}


export const setMessage = (message) => {

	return {
		type: SET_MESSAGE,
		message: message
	};

}
