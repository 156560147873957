export const ROUTES = {
    HOME_PAGE: '/',
    SIGNIN_PAGE: '/signin',
    SIGNUP_PAGE: '/signup',
    SIGNOUT_PAGE: '/signout',
    LOGOUT_PAGE: '/logout',
    SET_TEAM_NAME_PAGE: '/setteamname',
    JOIN_EVENT: '/join_event',
    EVENT_ADMIN_PAGE: '/eventadmin',
    GAME_PAGE: '/game',
    TEST_PAGE: '/test',
    GAME_DISPLAY_PAGE: '/gamedisplay',
    PASSWORD_RESET: '/passwordreset'
}