import React, { useEffect, useState } from 'react'
import './messagedisplay.scss';

const MessageDisplay = ({messageObj, playersList}) => {

    /* SET UP OF MESSAGES

    "message" is an object at the root level of a game
    "message.type" is one of:
        - WINNER : body and title not used
        - undefined : uses the generic message

    */

    let type = messageObj?.type || "GENERIC"
    let title = messageObj?.title || ""
    let body = messageObj?.body || ""

    let playersSortedArr = []
    let topScore = 0
    let winnersArr = []

    switch (type) {

        case "WINNER":

            playersSortedArr = playersList
                .sort((b,a) => (a.score > b.score) ? 1 : ((b.score > a.score) ? -1 : 0))
                .forEach((team) => {
                    if (team.score >= topScore) {
                        topScore = team.score
                        winnersArr.push(team.teamname)
                    }
                })

            return (
                <div className="winners-text">
                    <h1>{winnersArr.length > 1 ? "IT'S A TIE!" : "CONGRATULATIONS!"}</h1>
                    {winnersArr.map((w, i)=>{
                        return (<h2 key={i}>{w}</h2>)
                    })}
                </div>
            );

        default: 
            return (
                <div>
                    {title ? <h1>{title}</h1> : null}
                    {body ? <h2>{body}</h2> : null}
                </div>
            );
      
    }


}

export default MessageDisplay
