import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Navigate, Outlet, useParams, useLocation } from 'react-router-dom';

const AdminRoute = ({ children, isAllowed }) => {

  const location  = useLocation()

  if (!isAllowed) {
    return <Navigate to={'/signin'} state={{destination:location.pathname}} replace />;
  }
  return children ? children : <Outlet />;
}

export default AdminRoute