// Event reducer

import { 
  SET_AVAILABLE_QUESTIONS,
  SET_QUESTION_AS_UNAVAILABLE,
  SET_ACTIVE_GAME,
  END_ACTIVE_GAME,
} from './event.types'

const INITIAL_STATE = {
  activeGame: '2',
  availableQuestions: [],
};

const eventReducer = (state = INITIAL_STATE, action) => {

    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {

      case SET_AVAILABLE_QUESTIONS:

          return { 
            ...updatedState, 
            availableQuestions : action.payload.questionsArr
          };

      case SET_QUESTION_AS_UNAVAILABLE:

          updatedState.availableQuestions = updatedState.availableQuestions.map((obj)=>{
            if (obj.index == action.payload.qid) {
              console.log("SETTING AS FALSE", obj.question)
              obj.available = false
            }
            return obj
          })

          return { 
            ...updatedState, 
            //availableQuestions : updatedState.availableQuestions
          };

      case SET_ACTIVE_GAME:
          return { 
            ...updatedState, 
            activeGame: action.activeGame
          };

      case END_ACTIVE_GAME:
          return { 
            ...updatedState, 
            activeGame: 'none'
          };      

      default: return state;
      
    }

};

export default eventReducer;